export default {
  apiGateway: {
    REGION: "us-west-2",
    URL: "YOUR_API_GATEWAY_URL"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_zFqLU9JBV",
    APP_CLIENT_ID: "22c9tjdmaqkvo0rqcisqrvv5s2"
  }
};
